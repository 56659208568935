/* eslint no-console:0 */
import 'bootstrap'
import '../src/application.scss'

// Rails UJS
import Rails from 'rails-ujs'

require.context('../images', true, /\.(svg|png|gif|jpg|jpeg|ico)$/)

Rails.start()
